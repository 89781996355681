import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import media from "styled-media-query";

import Logo from "../Logo";
import Navigation from "../Navigation";
import Footer from "../Footer";

const GlobalStyles = createGlobalStyle`
  html {
    ext-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
  }

  img {
    pointer-events: none;
    user-select: none;
  }

  table {
    tr {

    }
    th:empty {
      display: none;
    }
    td {
      border-bottom: none;
      padding: 0;
    }
  }
`;

const LayoutWrapper = styled.div`
  background: white;
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  padding: 0 5em 1.25em;

  transition: background-color 300ms ease-in;

  ${media.lessThan("600px")`
     padding: 0 2em 1em;
  `}

  *::selection {
    background: rgb(255, 218, 185);
  }
  *::-moz-selection {
    background: rgb(255, 218, 185);
  }

  &.work {
    background: rgb(188, 210, 238);

    header {
      text-shadow: none;
    }

    nav a {
      text-shadow: none;
    }

    *::selection {
      background: rgb(255, 250, 205);
    }
    *::-moz-selection {
      background: rgb(255, 250, 205);
    }

    ${media.lessThan("600px")`
      header, nav {
         background: rgb(188,210,238);
      }
    `}
  }

  &.vita {
    background: rgb(255, 218, 185);

    header {
      text-shadow: none;
    }

    nav a {
      text-shadow: none;
    }

    *::selection {
      background: rgb(188, 210, 238);
    }
    *::-moz-selection {
      background: rgb(188, 210, 238);
    }

    ${media.lessThan("600px")`
      header, nav {
         background: rgb(255,218,185);
      }
    `}
  }

  &.contact {
    background: rgb(255, 250, 205);

    header {
      text-shadow: none;
    }

    nav a {
      text-shadow: none;
    }

    *::selection {
      background: rgb(188, 210, 238);
    }
    *::-moz-selection {
      background: rgb(188, 210, 238);
    }

    ${media.lessThan("600px")`
      header, nav {
        background: rgb(255,250,205);
      }
    `}
  }
`;

const Header = styled.header`
  position: fixed;
  top: 5em;
  left: 5em;
  z-index: 110;
  text-shadow: 1px 1px white;

  ${media.lessThan("medium")`
    top: 1.5em;
    left: 0;
    right: 0;
    padding: 0 5em;
 `}

  ${media.lessThan("600px")`
    top: 0;
    text-align: center;
    padding: 1em 2em;
    height: 40px;
    background: white;
    transition: background-color 300ms ease-in;
 `}
`;

const Main = styled.section`
  margin-left: 275px;
  position: relative;
  padding-top: 5em;
  padding-bottom: 5em;
  flex: 1;

  ${media.lessThan("medium")`
    padding-top: 1.5em;
     margin-left: 0;
  `}

  ${media.lessThan("600px")`
     padding-top: 2em;
  `}
`;

const Page = styled.div`
  margin-top: 10em;

  ${media.lessThan("medium")`
    margin-top: 5em;
  `}

  > div {
    max-width: 600px;
  }
  > .recent {
    max-width: none;
  }
`;

const Layout = ({ location, children }) => {
  const pathClass = location.pathname.split("/").join(" ");
  return (
    <React.Fragment>
      <LayoutWrapper className={pathClass}>
        <Header>
          <Logo>
            <Link to="/">Jennifer Dünser</Link>
          </Logo>
        </Header>

        <Main>
          <Navigation />
          <Page>{children}</Page>
        </Main>

        <Footer></Footer>
      </LayoutWrapper>

      <GlobalStyles />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
