import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import media from "styled-media-query";

const Nav = styled.nav`
  position: fixed;
  z-index: 110;
  line-height: normal;

  ${media.between("small", "medium")`
     margin-left: 100px;
     left: 200px;
  `}

  ${media.lessThan("600px")`
     left: 0;
     right: 0;
     padding: 1em 0;
     margin-left: 0;
     text-align: center;
     background: white;
     transition: background-color 300ms ease-in;
  `}

  a {
    font-family: futura-pt-bold, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 2.5px;
    margin: 0 2rem 0 0;
    text-shadow: 1px 1px white;
  }
  a:last-child {
    margin-right: 0;
  }
  a.active {
    border-bottom: 1px solid black;
  }
`;

const Navigation = () => (
  <Nav>
    <Link activeClassName="active" partiallyActive={true} to="/work">
      Work
    </Link>
    <Link activeClassName="active" partiallyActive={true} to="/vita">
      Vita
    </Link>
    <Link activeClassName="active" partiallyActive={true} to="/contact">
      Contact
    </Link>
  </Nav>
);

export default Navigation;
