import React from "react";
import styled from "styled-components";

import shoes from "../../../static/shoes.svg";

const Container = styled.footer`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 1rem;
`;

const Link = styled.a`
  position: relative;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  text-transform: lowercase;

  &:hover {
    text-decoration: none;
  }
`;

const Copyright = styled.div``;

const Symbol = styled.div`
  background-image: url(${shoes});
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  width: 90px;
  height: 20px;
`;

const Footer = () => (
  <Container>
    <Copyright>
      © {new Date().getFullYear()}
      {` `}
      <Link href="//jenniferduenser.at/">jenniferduenser.at</Link>
    </Copyright>
    <Symbol />
  </Container>
);

export default Footer;
